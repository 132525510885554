import { useEffect } from 'react';
// Types
import { MyPaymentMethods_myPaymentMethods } from 'api/payments/types/MyPaymentMethods';
// Components
import { Card } from './Card/Card';
// Styles
import styles from './PaymentCards.module.scss';

type PaymentCardsProps = {
  data: MyPaymentMethods_myPaymentMethods[];
  onPaymentSelect?: (methodId: string) => void;
};

const PaymentCards = ({ data, onPaymentSelect }: PaymentCardsProps) => {
  useEffect(() => {
    if (data?.length) {
      const defaultPaymentMethodId =
        data?.find((i) => i?.isDefault)?.methodId || '';

      if (onPaymentSelect) {
        onPaymentSelect(defaultPaymentMethodId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ul className={styles.paymentCardsWrapper}>
      {data?.map((payment) => (
        <li key={payment.id} className={styles.paymentCardItem}>
          <Card payment={payment} onSelect={onPaymentSelect} />
        </li>
      ))}
    </ul>
  );
};

export default PaymentCards;
