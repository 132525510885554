import { useState } from 'react';
import { useMutation } from '@apollo/client';
import cn from 'classnames';
// Api
import {
  REMOVE_PAYMENT_METHOD,
  SET_PAYMENT_METHOD_AS_DEFAULT,
} from 'api/payments/mutations';
import { MY_PAYMENT_METHODS } from 'api/payments/queries';
// Types
import {
  SetPaymentMethodAsDefault,
  SetPaymentMethodAsDefaultVariables,
} from 'api/payments/types/SetPaymentMethodAsDefault';
import {
  RemovePaymentMethod,
  RemovePaymentMethodVariables,
} from 'api/payments/types/RemovePaymentMethod';
import { MyPaymentMethods_myPaymentMethods } from 'api/payments/types/MyPaymentMethods';
// Ui
import RadioButton from 'ui3/RadioButton/RadioButton';
import Text from 'ui3/Text/Text';
import NotificationModal from 'ui3/NotificationModal/NotificationModal';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Icons
import TrashIcon from '../../../../public/images/icons/trash.svg';
// Styles
import styles from './Card.module.scss';

type CardProps = {
  payment: MyPaymentMethods_myPaymentMethods;
  onSelect?: (methodId: string, name: string) => void;
};

export const Card = ({ payment, onSelect }: CardProps) => {
  const { id, methodId, last4, cardholderName, expMonth, expYear } = payment;

  const [setPaymentMethodAsDefault] = useMutation<
    SetPaymentMethodAsDefault,
    SetPaymentMethodAsDefaultVariables
  >(SET_PAYMENT_METHOD_AS_DEFAULT, {
    refetchQueries: [MY_PAYMENT_METHODS],
  });

  const [
    removePaymentMethod,
    { loading: removePaymentMethodLoading },
  ] = useMutation<RemovePaymentMethod, RemovePaymentMethodVariables>(
    REMOVE_PAYMENT_METHOD,
    { refetchQueries: [MY_PAYMENT_METHODS] }
  );

  const [
    deletePaymentModalVisible,
    setDeletePaymentModalIsVisible,
  ] = useState<boolean>(false);

  const isActive = payment.isDefault;

  const onPaymentSelect = async () => {
    if (onSelect) {
      onSelect(methodId, cardholderName || '');
    }

    if (!payment.isDefault) {
      try {
        await setPaymentMethodAsDefault({
          variables: { id },
        });

        showToast({
          type: 'success',
          message: 'Default payment method has been changed successfully',
        });
      } catch (error) {
        showToast({
          message: error?.message || '',
          type: 'error',
        });
      }
    }
  };

  const onDeletePaymentModalOpen = () => {
    setDeletePaymentModalIsVisible(true);
  };

  const onDeletePaymentModalClose = () => {
    setDeletePaymentModalIsVisible(false);
  };

  const handleDeletePayment = async () => {
    try {
      await removePaymentMethod({
        variables: { id: payment?.id },
      });
      showToast({
        message: 'Payment card has been deleted successfully.',
      });
    } catch (err) {
      showToast({
        message: 'Looks like something went wrong. Please try again later.',
        type: 'error',
      });
    }
    onDeletePaymentModalClose();
  };

  return (
    <>
      <div
        className={cn(styles.root, {
          [styles.active]: isActive,
        })}
      >
        <div className={styles.section}>
          <RadioButton
            checked={isActive}
            onChange={onPaymentSelect}
            name={id}
            labelClassName={styles.labelItem}
          />

          <Text className={styles.cardNumber}>{last4}</Text>
        </div>

        <div className={styles.section}>
          <Text>
            {`${+expMonth < 10 ? `0` : ''}${expMonth}/${expYear.slice(2)}`}
          </Text>
          <button
            className={styles.cardAction}
            onClick={onDeletePaymentModalOpen}
            type="button"
          >
            <TrashIcon />
          </button>
        </div>
      </div>

      <NotificationModal
        open={deletePaymentModalVisible}
        type="confirm"
        title="Delete card"
        description={
          <>
            Are You sure to delete card <b>**** ${last4}</b>
          </>
        }
        onClose={onDeletePaymentModalClose}
        acceptLabel="Delete"
        onAcceptClick={handleDeletePayment}
        loading={removePaymentMethodLoading}
        acceptDisabled={removePaymentMethodLoading}
        withCancelButton
      />
    </>
  );
};
